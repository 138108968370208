import {Component} from '@angular/core';
import {FieldArrayType} from '@ngx-formly/core';

@Component({
  selector: 'formly-array-type',
  template: `
    <div class="mb-3">
      <legend *ngIf="props['label']">{{ props['label'] }}</legend>
      <p *ngIf="props['description']">{{ props['description'] }}</p>

      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <div *ngFor="let field of field.fieldGroup;let i = index;" class="row">
        <formly-field class="col-10" [field]="field"></formly-field>
        <div class="col-2 text-right btn-remove">
          <button class="btn btn-danger btn-sm" type="button" (click)="remove(i)">
            <i class="uil uil-minus"></i>
          </button>
        </div>
      </div>

      <div class="d-flex flex-row-reverse"
           *ngIf="!props['maxItems'] || props['maxItems'] > (field.fieldGroup?.length || 0)">
        <button class="btn btn-primary btn-sm" type="button" (click)="add()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  `,
  styles: ['.btn-remove {padding-top: 2rem}']
})
export class ArrayTypeComponent extends FieldArrayType {
}
