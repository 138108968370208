import {Injectable} from '@angular/core';
import {LoginResponse} from '../../models/auth/auth.model';
import {SavedCredentialsData} from "../../models/auth/token.model";
import {UserProfileModel} from "../../models/auth/profile.model";

/**
 * Provides token services including saving, retrieving, and deleting token from the local storage.
 * This services should not be used directly instead should be used via AuthService
 */
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  /**
   * key to store auth data
   */
  private localStorageAuthKey = '_auth_credentials';

  /**
   * Store saved credentials from local storage
   */
  private savedCredentials: SavedCredentialsData | undefined;

  /**
   * key to store user data
   */
  private localStorageUserKey = '_profile';

  /**
   * Token service constructor.
   * Load saved credentials, each time service is refreshed.
   */
  constructor() {
    this.updateSavedCredentials();
  }

  /**
   * Save login data to the local storage
   * @param authResponse LoginResponse data
   */
  public saveCredentials(authResponse: LoginResponse) {

    const data: SavedCredentialsData = {
      accessToken: authResponse.access_token,
      refreshToken: authResponse.refresh_token,
      tokenType: authResponse.token_type,
      expiresIn: authResponse.expires_in,
      scope: authResponse.scope,
      generatedTime: Date.now() / 1000,
      userValid: true
    };

    localStorage.setItem(this.localStorageAuthKey, JSON.stringify(data));

    this.updateSavedCredentials();

    return new Promise((resolve) => {
      resolve(this.savedCredentials);
    });
  }

  /**
   * Clear credentials.
   * Possibly called on logout
   */
  public clearCredentials() {
    // Return promise
    return new Promise((resolve) => {

      // Clear auth storage
      localStorage.removeItem(this.localStorageAuthKey);
      localStorage.removeItem(this.localStorageUserKey);

      // Update credentials data
      this.updateSavedCredentials();

      return resolve(null);
    });
  }

  /**
   * Get access token from the local storage
   * @return Access token or null
   */
  public accessToken(): string | null {
    if (!this.savedCredentials) {
      this.updateSavedCredentials();
    }

    if (this.savedCredentials) {
      return this.savedCredentials.accessToken;
    }

    return null;
  }

  /**
   * Get refresh token from the local storage.
   *
   * @return Refresh token or false
   */
  public refreshToken(): string {
    if (!this.savedCredentials) {
      this.updateSavedCredentials();
    }

    if (this.savedCredentials) {
      return this.savedCredentials.refreshToken;
    }

    throw new Error('Invalid refresh token');
  }

  /**
   * Get saved token type from the local storage.
   * @return Token type or false
   */
  public tokenType(): string | boolean {
    if (!this.savedCredentials) {
      this.updateSavedCredentials();
    }

    if (this.savedCredentials) {
      return this.savedCredentials.tokenType;
    }

    return false;
  }

  /**
   * Return True if user is valid, False otherwise.
   * The check is based on the access token stored in the local storage.
   * If access token is not available, the user is not valid.
   *
   * @return boolean True if valid, False otherwise
   */
  public userValid(): boolean {
    return this.savedCredentials ? this.savedCredentials.userValid : false;
  }

  /**
   * Check if access token is expired.
   * @return boolean True if access token is expired, False otherwise.
   */
  public isTokenExpired(): boolean {
    // If credentials are not saved
    // return true
    if (!this.savedCredentials) {
      return true;
    }

    /**
     * If token is expires, return true.
     *
     * The token expiry is checked using the generated time and the expires in timestamp
     * received in the login response.
     *
     * If the sum of the generated time and the expires in timestamp is less than current time, the token is
     * expired.
     */
    return this.generateTime() + this.expiresIn() < Date.now() / 1000;
  }

  /**
   * Return the time when the token was generated/saved
   * @return number|null
   */
  private generateTime(): number {
    return (this.savedCredentials as SavedCredentialsData).generatedTime ?? null;
  }

  /**
   * Return saved expires in timestamp
   * @return number|null
   */
  private expiresIn(): number {
    return (this.savedCredentials as SavedCredentialsData).expiresIn ?? null;
  }

  /**
   * Get data from the local storage and update the class member
   */
  private updateSavedCredentials() {
    const $item = localStorage.getItem(this.localStorageAuthKey);
    this.savedCredentials = $item ? JSON.parse($item) : null;
  }


  /**
   * Gets saved User Profile from local storage
   */
  public getUserProfile(): UserProfileModel | null {
    try {
      const $item = localStorage.getItem(this.localStorageUserKey)
      return $item ? JSON.parse($item) as UserProfileModel : null;
    } catch (e) {
      return null;
    }
  }


  /**
   * Updates saved User Profile in local storage
   */
  public updateUserProfile(data: UserProfileModel) {
    localStorage.setItem(this.localStorageUserKey, JSON.stringify(data));
  }
}
