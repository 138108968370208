import {Inject, Injectable, LOCALE_ID} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  /**
   * Set current language index
   */
  currentLanguageIndex = 0;

  /**
   * preferred languages for selection
   */
  languages = [
    {
      countryCode: 'US',
      language: 'English (EN)',
      languageCode: 'en',
    },
    {
      countryCode: 'ES',
      language: 'Español (ES)',
      languageCode: 'es'
    }
  ];

  /**
   * to store the language code for header
   */
  locale: string;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.locale = localeId.split('-')[0];
  }

  /**
   * to handle the accept language for header
   */
  getAcceptLanguage() {
    return this.locale || 'en';
  }

  /**
   * Change language of the page
   * @param code
   * @param path
   */
  changeLanguage(code: string, path: string | null = null) {
    let url;

    // const locationSplit = location.pathname.split('/');
    // To preserve query and hash params, remove origin from href to get path
    const locationSplit = location.href.replace(location.origin, '').split('/');

    // Extract default url path. Expecting there is no language code in the url.
    let urlPath = locationSplit.slice(1);

    // if first element in slice is language code, rebuild urlPath by excluding language code
    if (this.languages.map(m => m.languageCode).includes(locationSplit[1])) {
      urlPath = locationSplit.slice(2);
    }

    // Override url path to specific `path` value.
    if (path) {
      // Remove trailing slash
      path = path.replace(/^\/+/g, '');
      // Remove language code from path
      path = this.languages.map(m => m.languageCode).reduce((cl, p) => {
        if (cl.startsWith(`${p}/`)) {
          // The path contains the language code. Possibly this url is the `?continue=` url.
          // Set the code to redirect to the same code later
          code = p;       // Remove it, if not to use language code from `?continue=`
          return cl.replace(`${p}/`, '');
        }
        return cl
      }, path);

      urlPath = [path];
    }

    // Add language code to URL
    if (code === 'en') {
      url = `${location.origin}/${urlPath.join('/')}`;
    } else {
      url = `${location.origin}/${code}/${urlPath.join('/')}`;
    }

    // Add ?source=lang query parameter in the URL, in case
    // a. user does not have specific redirection to `path` parameter
    // b. current path is homepage
    if (!path) {
      const _url = new URL(url);
      // @ts-ignore
      const _searchParam = new URLSearchParams(url.search);

      // If root path, append `?source=lang` query param to url.
      // The query param will be detected by proxy server to prevent redirection
      // based on browser's language
      if (_url.pathname === '/') {
        if (!_searchParam.get('source')) {
          _searchParam.append('source', 'lang');
        }
      }

      // Update url query parameters
      _url.search = _searchParam.toString();
      url = _url.href;
    }

    window.open(
      url,
      '_self'
    );
  }

  /**
   * Set current language
   */
  setCurrentLanguage() {
    const locationSplit = location.pathname.split('/');

    // if first element in slice is language code, rebuild urlPath by excluding language code
    if (this.languages.map(m => m.languageCode).includes(locationSplit[1])) {
      this.currentLanguageIndex = this.languages.findIndex(l => l.languageCode === locationSplit[1]);
    }
  }
}
