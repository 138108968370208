import {Injectable} from '@angular/core';
// import {UserProfileModel} from '../../models/profile/profile.model';
// import {SubscriptionService} from '../subscription/subscription.service';
import {Location} from '@angular/common';
import {SubscriptionService} from "../subscription/subscription.service";
import {UserProfileModel} from "../../models/auth/profile.model";
import {QuotaData} from "../../models/subscription/subscription.model";

/**
 *  Quota service is used to implement Quota and permissions.
 *  If the user is SHARED ---- checks will be applied om QUOTA and PERMISSIONS
 *  If the user is not shared --- checks will be applied on only QUOTAS
 *
 *  If QUOTA is present, content will be displayed else not
 *  If Permission is not present field(s) will br disabled with lock popover(s)
 */
@Injectable({
  providedIn: 'root'
})
export class QuotaService {

  quota: Array<QuotaData>;
  profileData: UserProfileModel;
  isSharedUser = false;
  quotas = {
    // Custom Domain
    CUSTOM_DOMAIN: {
      key: 'CUSTOM_DOMAIN',
      messages: {
        limit_exceed: $localize`Custom domain limit exceeds`,
        not_allowed: $localize`You cannot access custom domain`
      }
    },
    // Analytics
    QR_ANALYTICS_BASIC: {
      key: 'QR_ANALYTICS_BASIC',
      messages: {
        not_allowed: $localize`You are not allowed to view Analytics`,
        limit_exceed: '',
      }
    },
    QR_ANALYTICS_ADVANCED: {
      key: 'QR_ANALYTICS_ADVANCED',
      messages: {
        not_allowed: $localize`You are not allowed to view Graphical Analytics`,
        limit_exceed: '',
      }
    },
    EXPORT_ANALYTICS_REPORT: {
      key: 'EXPORT_ANALYTICS_REPORT',
      messages: {
        not_allowed: $localize`You are not allowed to view Graphical Analytics`,
        limit_exceed: '',
      }
    },
    QR_ANALYTICS_GOOGLE_TRACKING: {
      key: 'QR_ANALYTICS_GOOGLE_TRACKING',
      messages: {
        not_allowed: $localize`You are not allowed to view Graphical Analytics`,
        limit_exceed: '',
      }
    },
    QR_ANALYTICS_WEBHOOK: {
      key: 'QR_ANALYTICS_WEBHOOK',
      messages: {
        not_allowed: $localize`You are not allowed to add webhook to retrieve analytics data`,
        limit_exceed: '',
      }
    },
    // Lead Generation
    LEAD_GENERATION: {
      key: 'LEAD_GENERATION',
      messages: {
        not_allowed: $localize`You are not allowed to access Lead Generation`,
        limit_exceed: '',
      }
    },
    LEAD_GENERATION_WEBHOOK: {
      key: 'LEAD_GENERATION_WEBHOOK',
      messages: {
        not_allowed: $localize`You are not allowed to access Lead Generation Webhooks`,
        limit_exceed: '',
      }
    },
    // Shared Users
    SHARED_USERS: {
      key: 'SHARED_USERS',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    // QR Manager
    DYNAMIC_QR_CODES: {
      key: 'DYNAMIC_QR_CODES',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    STATIC_QR_CODES: {
      key: 'STATIC_QR_CODES',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    TOTAL_QR_CODES: {
      key: 'TOTAL_QR_CODES',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    DESIGNER_QR_CODES: {
      key: 'DESIGNER_QR_CODES',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    CUSTOM_URL_HASH: {
      key: 'CUSTOM_URL_HASH',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    PASSWORD_PROTECTION: {
      key: 'PASSWORD_PROTECTION',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    HIGH_ACCURACY: {
      key: 'HIGH_ACCURACY',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    VECTOR_EXPORTING: {
      key: 'VECTOR_EXPORTING',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    RASTER_EXPORTING: {
      key: 'RASTER_EXPORTING',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    SAVE_DESIGN_TEMPLATE: {
      key: 'SAVE_DESIGN_TEMPLATE',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    TAG_MANAGEMENT: {
      key: 'TAG_MANAGEMENT',
      messages: {
        not_allowed: $localize`You are not allowed to access Shared Users`,
        limit_exceed: '',
      }
    },
    EMAIL_SUPPORT: {
      key: 'EMAIL_SUPPORT',
      messages: {
        not_allowed: $localize`You are not allowed to access Email Support`,
        limit_exceed: '',
      }
    },
    POWERED_BY_SCANOVA: {
      key: 'POWERED_BY_SCANOVA',
      messages: {
        not_allowed: '',
        limit_exceed: '',
      }
    },
    EVENT_TRACKING: {
      key: 'EVENT_TRACKING',
      messages: {
        not_allowed: $localize`You are not allowed to access Event Tracking`,
        limit_exceed: '',
      }
    },
    CUSTOM_SCRIPT: {
      key: 'CUSTOM_SCRIPT',
      messages: {
        not_allowed: $localize`You are not allowed to access Custom Script`,
        limit_exceed: '',
      }
    },
    BULK_OPERATION: {
      key: 'BULK_OPERATION',
      messages: {
        not_allowed: $localize`You are not allowed to access Bulk Operation`,
        limit_exceed: '',
      }
    },
    CUSTOM_ACCESS_LEVELS: {
      key: 'CUSTOM_ACCESS_LEVELS',
      messages: {
        not_allowed: $localize`You are not allowed to access Custom Access Levels`,
        limit_exceed: '',
      }
    },
    CUSTOM_TAG: {
      key: 'CUSTOM_TAG',
      messages: {
        not_allowed: $localize`You are not allowed to access Custom Tags`,
        limit_exceed: '',
      }
    },
    COMPONENT_VIDEO_UPLOAD: {
      key: 'COMPONENT_VIDEO_UPLOAD',
      messages: {
        not_allowed: $localize`You are not allowed to access component video upload`,
        limit_exceed: '',
      }
    },
    COMPONENT_FORM: {
      key: 'COMPONENT_FORM',
      messages: {
        not_allowed: $localize`You are not allowed to access Custom Form`,
        limit_exceed: '',
      }
    },
    COMPONENT_CONDITIONAL_REDIRECT: {
      key: 'COMPONENT_CONDITIONAL_REDIRECT',
      messages: {
        not_allowed: $localize`You are not allowed to access advance setting of website url qr`,
        limit_exceed: '',
      }
    },
    USER_TRACKING: {
      key: 'USER_TRACKING',
      messages: {
        not_allowed: $localize`You are not allowed to access User Tracking`,
        limit_exceed: '',
      }
    },
    AUTO_EXPIRE_QR: {
      key: 'AUTO_EXPIRE_QR',
      messages: {
        not_allowed: $localize`You are not allowed to access Auto-Deactivate QR Code`,
        limit_exceed: '',
      }
    },
    AGE_RESTRICTION: {
      key: 'AGE_RESTRICTION',
      messages: $localize`You are not allowed to access Age Restriction QR Code`,
      limit_exceed: ''
    },
    MANAGEMENT_API: {
      key: 'MANAGEMENT_API',
      messages: {
        not_allowed: $localize`You are not allowed to access Management API`,
        limit_exceed: '',
      }
    },
    MANAGEMENT_API_SANDBOX: {
      key: 'MANAGEMENT_API_SANDBOX',
      messages: {
        not_allowed: $localize`You are not allowed to access Management API Sandbox`,
        limit_exceed: '',
      }
    }
  };
  // hasQuota = true;
  quotasDummyData: Array<QuotaData> = [];
  quotasDefaultData = [
    {
      value: 0,
      codename: 'CUSTOM_DOMAIN',
      name: $localize`Custom Domain`,
      description: $localize`Custom domain limit exceeds`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'QR_CODE_WITH_CUSTOM_DOMAIN',
      name: $localize`Qr code with custom domain`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    // Analytics
    {
      value: 0,
      codename: 'QR_ANALYTICS_BASIC',
      name: $localize`Qr Analytics Basics`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'QR_ANALYTICS_ADVANCED',
      name: $localize`Qr Analytics Advanced`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'EXPORT_ANALYTICS_REPORT',
      name: $localize`Export Analytics Report`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'QR_ANALYTICS_GOOGLE_TRACKING',
      name: $localize`Qr code with custom domain`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'QR_ANALYTICS_WEBHOOK',
      name: $localize`Qr code with custom domain`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    //  Lead Generation
    {
      value: 0,
      codename: 'LEAD_GENERATION',
      name: $localize`Lead Generation`,
      description: $localize`Custom domain limit exceeds`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'LEAD_GENERATION_WEBHOOK',
      name: $localize`Lead Generation Webhook`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    //  Multi-user
    {
      value: 0,
      codename: 'SHARED_USERS',
      name: $localize`Shared Users`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    //   Qr Manager
    {
      value: 0,
      codename: 'DYNAMIC_QR_CODES',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'STATIC_QR_CODES',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'TOTAL_QR_CODES',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'DESIGNER_QR_CODES',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: false,
    },
    {
      value: 0,
      codename: 'CUSTOM_URL_HASH',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'PASSWORD_PROTECTION',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'HIGH_ACCURACY',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'VECTOR_EXPORTING',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'RASTER_EXPORTING',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'SAVE_DESIGN_TEMPLATE',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'TAG_MANAGEMENT',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    //  Extra
    {
      value: 0,
      codename: 'PRIORITY_SUPPORT',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'EMAIL_SUPPORT',
      name: $localize`Email Support`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'POWERED_BY_SCANOVA',
      name: $localize`Dynamic QR Codes`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'EVENT_TRACKING',
      name: $localize`Event Tracking`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'CUSTOM_SCRIPT',
      name: $localize`Custom Script`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'BULK_OPERATION',
      name: $localize`Bulk Operation`,
      description: $localize`Perform bulk operations on the QR codes. e.g., generate, download, update.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'CUSTOM_ACCESS_LEVELS',
      name: $localize`Custom Access Levels`,
      description: $localize`Create custom user roles and permissions.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'CUSTOM_TAG',
      name: $localize`Custom Tags`,
      description: $localize`Can create custom tags and set permission on them.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'COMPONENT_VIDEO_UPLOAD',
      name: $localize`Video Upload Component`,
      description: $localize`Allows uploading video in the video component`,
      unit: '',
      is_boolean: true
    },
    {
      value: 0,
      codename: 'COMPONENT_FORM',
      name: $localize`Form component`,
      description: $localize`Allow adding form with questions in the component`,
      unit: '',
      is_boolean: true
    },
    {
      value: 0,
      codename: 'COMPONENT_CONDITIONAL_REDIRECT',
      name: $localize`Conditional redirect component`,
      description: $localize`Allow advance setting in website url`,
      unit: '',
      is_boolean: true
    },
    {
      value: 0,
      codename: 'USER_TRACKING',
      name: $localize`User Tracking`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'AUTO_EXPIRE_QR',
      name: $localize`Auto Expire QR Code`,
      description: $localize`Set a expiry date and time for QR Code`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'AGE_RESTRICTION',
      name: $localize`Age Restrict QR Code`,
      description: $localize`Set age restriction for QR Code`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'MANAGEMENT_API',
      name: $localize`Management API`,
      description: "Management console API to access the QCG endpoints.",
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      codename: 'MANAGEMENT_API_SANDBOX',
      name: $localize`Management API Sandbox`,
      description: "Management API Sandbox environment API",
      unit: '',
      is_boolean: true,
    },
  ];
  permission = {
    // Analytics
    ANALYTICS_CAN_VIEW: {
      key: 'ANALYTICS_CAN_VIEW',
    },
    ANALYTICS_CAN_EXPORT: {
      key: 'ANALYTICS_CAN_EXPORT',
    },
    ANALYTICS_CAN_EXPORT_RAW: {
      key: 'ANALYTICS_CAN_EXPORT_RAW',
    },
    ANALYTICS_WEBHOOK_CAN_ADD: {
      key: 'ANALYTICS_WEBHOOK_CAN_ADD',
    },
    ANALYTICS_WEBHOOK_CAN_EDIT: {
      key: 'ANALYTICS_WEBHOOK_CAN_EDIT',
    },
    ANALYTICS_WEBHOOK_CAN_DELETE: {
      key: 'ANALYTICS_WEBHOOK_CAN_DELETE',
    },
    ANALYTICS_NOTIFICATION_CAN_VIEW: {
      key: 'ANALYTICS_NOTIFICATION_CAN_VIEW',
    },
    ANALYTICS_NOTIFICATION_CAN_UPDATE: {
      key: 'ANALYTICS_NOTIFICATION_CAN_UPDATE',
    },
    ANALYTICS_CANNOT_VIEW_IP_ADDRESS: {
      key: 'ANALYTICS_CANNOT_VIEW_IP_ADDRESS'
    },
    ANALYTICS_CANNOT_VIEW_EXACT_GPS: {
      key: 'ANALYTICS_CANNOT_VIEW_EXACT_GPS'
    },
    ANALYTICS_CANNOT_VIEW_PHYSICAL_LOCATION: {
      key: 'ANALYTICS_CANNOT_VIEW_PHYSICAL_LOCATION'
    },
    ANALYTICS_CAN_EXPORT_REPORT: {
      key: 'ANALYTICS_CAN_EXPORT_REPORT'
    },
    ANALYTICS_CAN_VIEW_ALL_USERS: {
      key: 'ANALYTICS_CAN_VIEW_ALL_USERS'
    },

    // Custom Domain
    CUSTOM_DOMAIN_CAN_ADD: {
      key: 'CUSTOM_DOMAIN_CAN_ADD',
    },
    CUSTOM_DOMAIN_CAN_DELETE: {
      key: 'CUSTOM_DOMAIN_CAN_DELETE',
    },
    CUSTOM_DOMAIN_CAN_VIEW: {
      key: 'CUSTOM_DOMAIN_CAN_VIEW',
    },
    // Lead Generation
    LEAD_GENERATION_CAN_ADD: {
      key: 'LEAD_GENERATION_CAN_ADD',
    },
    LEAD_GENERATION_CAN_EDIT: {
      key: 'LEAD_GENERATION_CAN_EDIT',
    },
    LEAD_GENERATION_CAN_DELETE: {
      key: 'LEAD_GENERATION_CAN_DELETE',
    },
    LEAD_GENERATION_ENTRY_CAN_VIEW: {
      key: 'LEAD_GENERATION_ENTRY_CAN_VIEW',
    },
    LEAD_GENERATION_ENTRY_CAN_DELETE: {
      key: 'LEAD_GENERATION_ENTRY_CAN_DELETE',
    },
    LEAD_GENERATION_ANALYTICS_CAN_VIEW: {
      key: 'LEAD_GENERATION_ANALYTICS_CAN_VIEW',
    },
    LEAD_GENERATION_WEBHOOK_CAN_ADD: {
      key: 'LEAD_GENERATION_WEBHOOK_CAN_ADD',
    },
    LEAD_GENERATION_WEBHOOK_CAN_EDIT: {
      key: 'LEAD_GENERATION_WEBHOOK_CAN_EDIT',
    },
    LEAD_GENERATION_WEBHOOK_CAN_DELETE: {
      key: 'LEAD_GENERATION_WEBHOOK_CAN_DELETE',
    },
    // Shared User
    SHARED_USER_CAN_VIEW: {
      key: 'SHARED_USER_CAN_VIEW',
    },
    SHARED_USER_CAN_ADD: {
      key: 'SHARED_USER_CAN_ADD',
    },
    SHARED_USER_CAN_EDIT: {
      key: 'SHARED_USER_CAN_EDIT',
    },
    SHARED_USER_CAN_DELETE: {
      key: 'SHARED_USER_CAN_DELETE',
    },
    SHARED_USER_CAN_EXPORT: {
      key: 'SHARED_USER_CAN_EXPORT',
    },
    //  QR Manager
    QR_CODE_CAN_ADD: {
      key: 'QR_CODE_CAN_ADD',
    },
    QR_CODE_CAN_VIEW: {
      key: 'QR_CODE_CAN_VIEW',
    },
    QR_CODE_CAN_EDIT: {
      key: 'QR_CODE_CAN_EDIT',
    },
    QR_CODE_CAN_DELETE: {
      key: 'QR_CODE_CAN_DELETE',
    },
    QR_CODE_CAN_DOWNLOAD: {
      key: 'QR_CODE_CAN_DOWNLOAD',
    },
    QR_CODE_TAG_CAN_ADD: {
      key: 'QR_CODE_TAG_CAN_ADD',
    },
    QR_CODE_CAN_EXPORT: {
      key: 'QR_CODE_CAN_EXPORT',
    },
    // subscription
    BILLING_CAN_CREATE: {
      key: 'BILLING_CAN_CREATE',
    },
    BILLING_CAN_VIEW: {
      key: 'BILLING_CAN_VIEW',
    },
    BILLING_INVOICE_CAN_DOWNLOAD: {
      key: 'BILLING_INVOICE_CAN_DOWNLOAD',
    },
    EMAIL_SUPPORT: {
      key: 'EMAIL_SUPPORT',
    },
    EVENT_TRACKING_ANALYTICS_CAN_VIEW: {
      key: 'EVENT_TRACKING_ANALYTICS_CAN_VIEW',
    },
    EVENT_TRACKING_CAN_VIEW: {
      key: 'EVENT_TRACKING_CAN_VIEW',
    },
    EVENT_TRACKING_CAN_EDIT: {
      key: 'EVENT_TRACKING_CAN_EDIT',
    },
    EVENT_TRACKING_CAN_ADD: {
      key: 'EVENT_TRACKING_CAN_ADD',
    },
    EVENT_TRACKING_CAN_DELETE: {
      key: 'EVENT_TRACKING_CAN_DELETE',
    },
    EVENT_TRACKING_CAN_SETUP: {
      key: 'EVENT_TRACKING_CAN_SETUP',
    },
    BULK_OPERATION_CAN_DOWNLOAD: {
      key: 'BULK_OPERATION_CAN_DOWNLOAD',
    },
    BULK_OPERATION_CAN_GENERATE: {
      key: 'BULK_OPERATION_CAN_GENERATE',
    },
    BULK_OPERATION_CAN_UPDATE: {
      key: 'BULK_OPERATION_CAN_UPDATE',
    },
    CUSTOM_ACCESS_LEVELS_CAN_VIEW: {
      key: 'CUSTOM_ACCESS_LEVELS_CAN_VIEW',
    },
    CUSTOM_ACCESS_LEVELS_CAN_DELETE: {
      key: 'CUSTOM_ACCESS_LEVELS_CAN_DELETE',
    },
    CUSTOM_ACCESS_LEVELS_CAN_EDIT: {
      key: 'CUSTOM_ACCESS_LEVELS_CAN_EDIT',
    },
    CUSTOM_ACCESS_LEVELS_CAN_ADD: {
      key: 'CUSTOM_ACCESS_LEVELS_CAN_ADD',
    },
    SCAN_DATA_COLLECT_CAN_DOWNLOAD: {
      key: 'SCAN_DATA_COLLECT_CAN_DOWNLOAD'
    },
    CUSTOM_TAG_CAN_VIEW: {
      key: 'CUSTOM_TAG_CAN_VIEW'
    },
    CUSTOM_TAG_CAN_ADD: {
      key: 'CUSTOM_TAG_CAN_ADD'
    },
    CUSTOM_TAG_CAN_DELETE: {
      key: 'CUSTOM_TAG_CAN_DELETE'
    },
    USER_ROLE_CAN_ASSIGN_TAG: {
      key: 'USER_ROLE_CAN_ASSIGN_TAG'
    },
    USER_TRACKING_CAN_VIEW: {
      key: 'USER_TRACKING_CAN_VIEW'
    },
    USER_TRACKING_CAN_UPDATE: {
      key: 'USER_TRACKING_CAN_UPDATE'
    },
    //Management API Keys
    MANAGEMENT_API_KEY_CAN_ADD: {
      key: 'MANAGEMENT_API_KEY_CAN_ADD'
    },
    MANAGEMENT_API_KEY_CAN_VIEW: {
      key: 'MANAGEMENT_API_KEY_CAN_VIEW'
    },
    MANAGEMENT_API_KEY_CAN_DELETE: {
      key: 'MANAGEMENT_API_KEY_CAN_DELETE'
    }
  };


  /*
  *  Permissions
  * */
  permissionDummyData = [

    //Management API
    {
      value: 0,
      code: 'MANAGEMENT_API_KEY_CAN_ADD',
      name: $localize`Can add management api key`,
      description: 'Can add the management API key.',
      unit: '',
      is_boolean: true
    },
    {
      value: 0,
      code: 'MANAGEMENT_API_KEY_CAN_VIEW',
      name: $localize`Can view management api key`,
      description: 'Can view the management API key.',
      unit: '',
      is_boolean: true
    },
    {
      value: 0,
      code: 'MANAGEMENT_API_KEY_CAN_DELETE',
      name: $localize`Can delete the management api key`,
      description: 'Can delete the management API key.',
      unit: '',
      is_boolean: true
    },

    // Analytics
    {
      value: 0,
      code: 'ANALYTICS_CAN_VIEW',
      name: $localize`Analytics can view`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'ANALYTICS_CAN_EXPORT',
      name: $localize`Analytics can view`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'ANALYTICS_CAN_EXPORT_RAW',
      name: $localize`Analytics can view`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'ANALYTICS_WEBHOOK_CAN_ADD',
      name: $localize`Analytics can view`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'ANALYTICS_WEBHOOK_CAN_EDIT',
      name: $localize`Analytics can edit webhook`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_WEBHOOK_CAN_DELETE',
      name: $localize`Analytics can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_NOTIFICATION_CAN_VIEW',
      name: $localize`Can view analytics notification`,
      description: $localize`Can view analytics notification.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_NOTIFICATION_CAN_UPDATE',
      name: $localize`Can update analytics notification`,
      description: $localize`Can update analytics notification.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_CANNOT_VIEW_IP_ADDRESS',
      name: $localize`Can not view ip address`,
      description: $localize`Can not view ip address.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_CANNOT_VIEW_EXACT_GPS',
      name: $localize`Can not view exact gps`,
      description: $localize`Can not view exact gps`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_CANNOT_VIEW_PHYSICAL_LOCATION',
      name: $localize`Can not view physical location`,
      description: $localize`Can not view physical location.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_CAN_VIEW_ALL_USERS',
      name: $localize`Can view analytics for all QR Codes from all users`,
      description: $localize`Can view analytics for all QR Codes from all users.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'ANALYTICS_CAN_EXPORT_REPORT',
      name: $localize`Can create and export Reports`,
      description: $localize`Can create and export Reports`,
      unit: '',
      is_boolean: true,

    },

    // Lead Generation
    {
      value: 0,
      code: 'LEAD_GENERATION_CAN_ADD',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'LEAD_GENERATION_CAN_EDIT',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'LEAD_GENERATION_CAN_DELETE',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'LEAD_GENERATION_ENTRY_CAN_VIEW',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,

    },
    {
      value: 0,
      code: 'LEAD_GENERATION_ENTRY_CAN_DELETE',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'LEAD_GENERATION_ANALYTICS_CAN_VIEW',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'LEAD_GENERATION_WEBHOOK_CAN_ADD',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'LEAD_GENERATION_WEBHOOK_CAN_EDIT',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'LEAD_GENERATION_WEBHOOK_CAN_DELETE',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },

    //  Shared Users
    {
      value: 0,
      code: 'SHARED_USER_CAN_VIEW',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'SHARED_USER_CAN_ADD',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'SHARED_USER_CAN_EDIT',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'SHARED_USER_CAN_DELETE',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'SHARED_USER_CAN_EXPORT',
      name: $localize`Shared user can export`,
      description: $localize`Shared user can export`,
      unit: '',
      is_boolean: true,
    },
    // Custom Domain
    {
      value: 0,
      code: 'CUSTOM_DOMAIN_CAN_ADD',
      name: $localize`Can Add Custom Domain`,
      description: $localize`Can add custom domain`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_DOMAIN_CAN_DELETE',
      name: $localize`Can Delete Custom Domain`,
      description: $localize`Can delete custom domain`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_DOMAIN_CAN_VIEW',
      name: $localize`Can view custom domain`,
      description: $localize`Can view custom domain`,
      unit: '',
      is_boolean: true,
    },
    //  QR Manager
    {
      value: 0,
      code: 'QR_CODE_CAN_ADD',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'QR_CODE_CAN_VIEW',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },

    {
      value: 0,
      code: 'QR_CODE_CAN_DELETE',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'QR_CODE_CAN_DOWNLOAD',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'QR_CODE_TAG_CAN_ADD',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'QR_CODE_CAN_EDIT',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'QR_CODE_CAN_EXPORT',
      name: $localize`Lead Gen can add delete`,
      description: $localize`Track scanning activity. You can also edit the content of the code without changing the design.`,
      unit: '',
      is_boolean: true,
    },
    //  Billing
    {
      value: 0,
      code: 'BILLING_CAN_CREATE',
      name: $localize`Can create/update billing`,
      description: $localize`Can create a new order, change plan, cancel recurring`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'BILLING_CAN_VIEW',
      name: $localize`Can view billing`,
      description: $localize`Can view billing, order history, subscription`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'BILLING_INVOICE_CAN_DOWNLOAD',
      name: $localize`Can download billing invoice`,
      description: $localize`Can download billing invoice`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'EVENT_TRACKING_ANALYTICS_CAN_VIEW',
      name: $localize`Can view event tracking analytics`,
      description: $localize`Can view event tracking analytics`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'EVENT_TRACKING_CAN_VIEW',
      name: $localize`Can view event tracking`,
      description: $localize`Can view event tracking integrations in the QR codes`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'EVENT_TRACKING_CAN_EDIT',
      name: $localize`Can edit event tracking`,
      description: $localize`Can edit event tracking integrations in the QR codes`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'EVENT_TRACKING_CAN_ADD',
      name: $localize`Can add event tracking`,
      description: $localize`Can add event tracking integrations in the QR codes`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'EVENT_TRACKING_CAN_DELETE',
      name: $localize`Can delete event tracking`,
      description: $localize`Can delete event tracking integrations in the QR codes`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'EVENT_TRACKING_CAN_SETUP',
      name: $localize`Can setup event tracking`,
      description: $localize`Can setup event tracking enable or disable`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'BULK_OPERATION_CAN_DOWNLOAD',
      name: $localize`Bulk Operation Can Download`,
      description: $localize`Users can download QR codes in bulk.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'BULK_OPERATION_CAN_GENERATE',
      name: $localize`Bulk Operation Can Generate`,
      description: $localize`Users can generate QR codes in bulk.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'BULK_OPERATION_CAN_UPDATE',
      name: $localize`Bulk Operation Can Update`,
      description: $localize`Users can update QR codes in bulk.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_ACCESS_LEVELS_CAN_ADD',
      name: $localize`Can add custom access levels`,
      description: $localize`Can add custom Access Levels.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_ACCESS_LEVELS_CAN_DELETE',
      name: $localize`Can delete custom access level`,
      description: $localize`Can delete custom access level.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_ACCESS_LEVELS_CAN_EDIT',
      name: $localize`Can edit custom access levels`,
      description: $localize`Can edit custom access levels.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_ACCESS_LEVELS_CAN_VIEW',
      name: $localize`Can view custom access levels`,
      description: $localize`Can view custom access levels.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'SCAN_DATA_COLLECT_CAN_DOWNLOAD',
      name: $localize`SCAN DATA COLLECT CAN DOWNLOAD`,
      description: $localize`Users Can download data submitted by end-users.`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_TAG_CAN_VIEW',
      name: $localize`Can view custom tag`,
      description: $localize`Can view custom tag`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_TAG_CAN_ADD',
      name: $localize`Can add custom tags`,
      description: $localize`Can add custom tags`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'CUSTOM_TAG_CAN_DELETE',
      name: $localize`Can delete custom tags`,
      description: $localize`Can delete custom tags`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'USER_ROLE_CAN_ASSIGN_TAG',
      name: $localize`Can assign tags on roles`,
      description: $localize`Can assign tags on roles`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'USER_TRACKING_CAN_UPDATE',
      name: $localize`User tracking can update`,
      description: $localize`Can update user tracking`,
      unit: '',
      is_boolean: true,
    },
    {
      value: 0,
      code: 'USER_TRACKING_CAN_VIEW',
      name: $localize`User tracking can view`,
      description: $localize`Can view user tracking`,
      unit: '',
      is_boolean: true,
    },
  ];
  permissionData: any;
  isUserValid = true;
  /**
   * permission to initialize with false value
   */
  permissionWithFalseValue = [
    'ANALYTICS_CANNOT_VIEW_IP_ADDRESS',
    'ANALYTICS_CANNOT_VIEW_PHYSICAL_LOCATION',
    'ANALYTICS_CANNOT_VIEW_EXACT_GPS'
  ];
  /**
   * key to store auth data
   */
  private profileKey = 'userProfile';
  private stats: any;

  /**
   * Quota service constructor.
   */
  constructor(
    private subscriptionService: SubscriptionService,
    private location: Location
  ) {

    this.quotasDummyData = JSON.parse(JSON.stringify(this.quotasDefaultData));

    /**
     * Set quotas and permissions > when user subscribes to new plan
     */
    this.subscriptionService.planChange.subscribe(res => {
      this.quotasDummyData = JSON.parse(JSON.stringify(this.quotasDefaultData));
    });
  }

  /**
   * Set Quota and permission
   */
  setQuotasAndPermissions() {
    this.profileData = JSON.parse(<string>localStorage.getItem(this.profileKey));

    if (this.profileData) {
      this.isSharedUser = this.profileData.user_type === 'shared';
      this.quota = this.profileData?.subscription?.plan.quotas || [];
      // this.stats = this.profileData.stats;
      this.permissionData = this.profileData.permissions;
    }
  }


  filterQuota(key: string) {
    if (this.quota === undefined) {
      this.setQuotasAndPermissions();
    }

    return this.quota.filter(c => {
      return c.codename === key;
    })[0];
  }

  /**
   *  Function used to check if the user is shared or not
   */
  isUserShared() {
    return this.isSharedUser;
  }


  /**
   * Check if user has quota available
   * @param quotaItem
   * @param limit
   */
  hasQuota(quotaItem: any, limit = 0): boolean {
    const profile = JSON.parse(<string>localStorage.getItem('userProfile')) as UserProfileModel;

    if ((this.quota === undefined || !this.quota) && profile) {
      this.quota = profile?.subscription?.plan.quotas || [];
      // this.stats = profile.stats;
    }

    if (!this.quota) {
      return false;
    }

    if (this.isUserValid) {
      this.quota.forEach((val: QuotaData) => {
        this.quotasDummyData.filter(c => {
          if (c.codename === val.codename) {
            c.value = val.value;
            c.is_boolean = true;
          }
        });
      });


      const d = this.quotasDummyData.filter(c => {
        return c.codename === quotaItem.key;
      });

      if (!d.length) {
        return false;
      }

      const quota = d[0];

      if (quota.is_boolean) {
        return Boolean(quota.value);
      }
      if (quota.value === -1) {
        return true;
      }
      return limit < quota.value;
    }

    return true;
  }


  /**
   * Below function is used to return the data in case of shared user, If user is not-shared : it will return true
   * @param permissionItem
   * @param limit
   */
  hasPermission(permissionItem: any, limit = 0) {
    if (this.isUserValid) {
      const profile = JSON.parse(<string>localStorage.getItem('userProfile')) as UserProfileModel;
      this.isSharedUser = profile ? profile.user_type === 'shared' : this.isSharedUser;

      if (this.isSharedUser) {

        if (this.permissionData === undefined || !this.permissionData) {
          this.permissionData = profile.permissions;
        }

        this.permissionData.forEach((val: any) => {
          this.permissionDummyData.filter(c => {
            if (c.code === val.code) {
              c.value = 1;
              c.is_boolean = true;
            }
          });
        });

        const d = this.permissionDummyData.filter(c => {
          return c.code === permissionItem.key;
        });

        const permission = d[0];

        if (permission.is_boolean) {
          return Boolean(permission.value);
        }
      } else {
        return !this.permissionWithFalseValue.includes(permissionItem.key);

      }
    }

    return true;
  }

  /**
   * Return permission if user can view QR codes
   */
  canViewQR() {
    if (this.isSharedUser) {
      return this.hasPermission(this.permission.QR_CODE_CAN_VIEW);
    }

    return true;
  }

  /**
   * Return permission if user can create QR codes
   */
  canCreateQR() {
    if (this.isSharedUser) {
      return (this.hasPermission(this.permission.QR_CODE_CAN_ADD) || this.hasPermission(this.permission.QR_CODE_CAN_EDIT));
    }

    return true;
  }

  /**
   * Return permission if user can access analytics
   */
  canAccessAnalytics() {
    if (this.isSharedUser) {
      return (this.hasPermission(this.permission.ANALYTICS_CAN_EXPORT) ||
        (this.hasPermission(this.permission.ANALYTICS_CAN_EXPORT_RAW)) ||
        (this.hasPermission(this.permission.ANALYTICS_CAN_VIEW)) ||
        (this.hasPermission(this.permission.ANALYTICS_WEBHOOK_CAN_DELETE)) ||
        (this.hasPermission(this.permission.ANALYTICS_WEBHOOK_CAN_EDIT)) ||
        (this.hasPermission(this.permission.ANALYTICS_WEBHOOK_CAN_ADD)));
    }

    return true;
  }

  /**
   * Return permission if user can access leads
   */
  canAccessLeads() {
    if (this.isSharedUser) {
      return (this.hasPermission(this.permission.LEAD_GENERATION_CAN_ADD) ||
        this.hasPermission(this.permission.LEAD_GENERATION_CAN_DELETE) ||
        this.hasPermission(this.permission.LEAD_GENERATION_CAN_EDIT) ||
        this.hasPermission(this.permission.LEAD_GENERATION_WEBHOOK_CAN_DELETE) ||
        this.hasPermission(this.permission.LEAD_GENERATION_WEBHOOK_CAN_EDIT) ||
        this.hasPermission(this.permission.LEAD_GENERATION_CAN_ADD) ||
        this.hasPermission(this.permission.LEAD_GENERATION_ANALYTICS_CAN_VIEW) ||
        this.hasPermission(this.permission.LEAD_GENERATION_ENTRY_CAN_VIEW) ||
        this.hasPermission(this.permission.LEAD_GENERATION_CAN_DELETE));
    }

    return true;
  }

  /**
   * Return permission if user can access BO
   */
  canAccessBulkOperation() {
    if (this.isSharedUser) {
      return (this.hasPermission(this.permission.BULK_OPERATION_CAN_DOWNLOAD) ||
        this.hasPermission(this.permission.BULK_OPERATION_CAN_UPDATE) ||
        this.hasPermission(this.permission.BULK_OPERATION_CAN_GENERATE));
    }

    return true;
  }

  /**
   * Return permission if user can access settings
   */
  canAccessSettings() {
    if (this.isSharedUser) {
      return (this.hasPermission(this.permission.CUSTOM_DOMAIN_CAN_DELETE) ||
          this.hasPermission(this.permission.CUSTOM_DOMAIN_CAN_ADD) ||
          this.hasPermission(this.permission.SHARED_USER_CAN_ADD) ||
          this.hasPermission(this.permission.SHARED_USER_CAN_EDIT) ||
          this.hasPermission(this.permission.SHARED_USER_CAN_VIEW) ||
          this.hasPermission(this.permission.SHARED_USER_CAN_DELETE) ||
          this.hasPermission(this.permission.CUSTOM_ACCESS_LEVELS_CAN_ADD) ||
          this.hasPermission(this.permission.CUSTOM_ACCESS_LEVELS_CAN_EDIT) ||
          this.hasPermission(this.permission.CUSTOM_ACCESS_LEVELS_CAN_VIEW) ||
          this.hasPermission(this.permission.CUSTOM_ACCESS_LEVELS_CAN_DELETE) ||
          this.hasPermission(this.permission.CUSTOM_TAG_CAN_VIEW) ||
          this.hasPermission(this.permission.CUSTOM_TAG_CAN_ADD) ||
          this.hasPermission(this.permission.CUSTOM_TAG_CAN_DELETE) ||
          this.hasPermission(this.permission.USER_ROLE_CAN_ASSIGN_TAG)) ||
        this.hasPermission(this.permission.SHARED_USER_CAN_EXPORT);
    }

    return true;
  }

  /**
   * Return permission if user can access Subscription
   */
  canAccessSubscription() {
    if (this.isSharedUser) {
      return (this.hasPermission(this.permission.BILLING_CAN_CREATE) ||
        this.hasPermission(this.permission.BILLING_CAN_VIEW) ||
        this.hasPermission(this.permission.BILLING_INVOICE_CAN_DOWNLOAD));
    }

    return true;
  }

  /**
   *
   * Function used to check the if qr code's creation is allowed or not at the time of Create QR
   * Keeping in mind - Quotas will work only for non-shared users.
   * Else return true for shared users (since shared users are handled by permissions)
   * @param key
   */
  checkQrQuotaLimit(key: string) {
    this.profileData = JSON.parse(<string>localStorage.getItem(this.profileKey));

    if (!this.isSharedUser) {
      const obj = this.filterQuota(key);


      if (key === 'STATIC_QR_CODES') {
        this.stats.static_qr_count = this.stats.static_qr_count + 1;
        return obj.value === -1 ? true : this.stats.static_qr_count <= obj.value;
      }

      if (key === 'DYNAMIC_QR_CODES') {

        this.stats.dynamic_qr_count = this.stats.dynamic_qr_count + 1;
        return obj.value === -1 ? true : this.stats.dynamic_qr_count <= obj.value;
      }

      if (key === 'TOTAL_QR_CODES') {
        this.stats.total_qr_count = this.stats.total_qr_count + 1;
        return obj.value === -1 ? true : this.stats.total_qr_count <= obj.value;
      }
    }

    return true;
  }

  /**
   * Store Quota and permission data
   * @param user UserProfileModel
   */
  storeQuotaAndPermissionsData(user: UserProfileModel) {
    if (user) {
      this.isUserValid = true;
      this.isSharedUser = user.user_type === 'shared';
      this.quota = user?.subscription?.plan?.quotas || [];
      // this.stats = user.stats;
      this.permissionData = user.permissions;
    } else {
      this.isUserValid = true;
    }
  }

  /**
   * Pass an object, and it will return true if all values are false else true
   * @param object
   */
  checkIfAllPermissionsFalse(object: {}) {
    return Object.values(object).every(item => !item);
  }

  /**
   * Pass an object, and it will return true if all values are false else true
   * @param object
   */
  checkIfSomePermissionsTrue(object: any) {
    return Object.values(object).some(item => item);
  }


  /**
   * Check custom plan and quota, if custom plan is true and quota is false then redirect to previous page
   * quota: Quota name that need to be checked
   */
  checkIfCustomPlanAndQuotaValid(quota: any, redirect = false) {

    if (!this.subscriptionService.isCustomPlan) {
      return true;
    }

    const hasQuota = this.hasQuota(quota);

    if (!hasQuota) {
      if (redirect) {
        this.location.back();
      }
      return false;
    } else {
      return true;
    }
  }
}
