import {FieldType, FieldTypeConfig} from "@ngx-formly/core";
import {Component} from "@angular/core";

@Component({
  selector: 'formly-field-input',
  template: `
    <input [type]="props['type']" class="form-control form-control-sm" [formControl]="formControl" [formlyAttributes]="field">
    <form-errors [control]="formControl"></form-errors>
  `
})
export class InputTypeComponent extends FieldType<FieldTypeConfig> {
}
