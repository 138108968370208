import {Injectable} from "@angular/core";
import {AppHttpClient} from "../../../app-http-client";
import {Observable, Subject} from "rxjs";
import {ChangeEmailModel, ChangePasswordModel, UserProfileModel} from "../../models/auth/profile.model";
import {TokenService} from "./token.service";

@Injectable({
  providedIn: "root"
})
export class ProfileService {

  /**
   * Auth user endpoint
   */
  private userEndpoint = 'auth/user/';
  public profileChange: Subject<UserProfileModel> = new Subject<UserProfileModel>();
  private endpoint = 'auth/';

  constructor(
    private appHttp: AppHttpClient,
    private tokenService: TokenService
  ) {
  }

  /**
   * ************************
   * User Information
   * ************************
   */

  /**
   * Gets User Information
   */
  public getUser(): Observable<UserProfileModel> {
    const url = `${this.userEndpoint}`;
    return this.appHttp.Get(url);
  }

  /**
   * Update User || User Info Section
   * @param data User payload
   */
  public updateUser(data: any): Observable<UserProfileModel> {
    const url = `${this.userEndpoint}`;
    return this.appHttp.Patch(url, data);
  }


  /**
   * ************************
   * Login Methods
   * ************************
   */

  /**
   * Updates Email
   * @param data email payload
   */
  public changeEmail(data: { email: string; password: string }): Observable<ChangeEmailModel> {
    const url = `auth/email/change/`;
    return this.appHttp.Put(url, data);
  }

  /**
   * Updates Password
   * @param data password payload
   */
  public changePassword(data: { new_password1: string; new_password2: string; old_password: string }): Observable<ChangePasswordModel> {
    const url = `auth/password/change/`;
    return this.appHttp.Post(url, data);
  }

  /**
   * ************************
   * Account
   * ************************
   */

  /**
   * Delete account
   * @param data password payload
   */
  public deleteAccount(data: { password: string }): Observable<any> {
    const url = `auth/delete/`;
    return this.appHttp.Post(url, data);
  }

  /**
   * Gets saved User Profile from local storage
   */
  public getUserProfile(): UserProfileModel | null {
    return this.tokenService.getUserProfile();
  }


  /**
   * Updates saved User Profile in local storage
   */
  public updateUserProfile(data: UserProfileModel) {
    this.tokenService.updateUserProfile(data);
    this.profileChange.next(data);
  }
}
