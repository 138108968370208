import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "./auth.service";
import {inject} from "@angular/core";
import {map} from "rxjs/operators";
import {catchError} from "rxjs";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.userValid()) {
    // console.log('3a user is not valid...');
    return cannotActivate(router, authService);
  }
  // else {
  //   authService.refreshToken().pipe(
  //     map(() => {
  //       return authService.isAuthenticated() || cannotActivate(router, authService)
  //     }),
  //     catchError(() => {
  //       return cannotActivate(router, authService);
  //     })
  //   )
  // }

  return true;
};


const cannotActivate = (router: Router, authService: AuthService) => {
  const url = location.pathname;
  // console.log('3a logging out user...');
  authService.logout().finally(() => {
    // console.log('3a redirecting user...');
    return router.navigate(['/auth/login'], {queryParams: {continue: url}});
  });

  return false;
}
