import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard} from "./@shared/services/auth/auth.guard";

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./@layout/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
  },
  {
    path: '',
    loadChildren: () => import('./@layout/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
    canActivate: [authGuard],
    data: {
      breadcrumb: {
        info: 'home'
      }
    }
  },
  {
    path: '',
    loadChildren: () => import('./@layout/payment-layout/payment-layout.module').then(m => m.PaymentLayoutModule),
    canActivate: [authGuard],
  },
  {
    path: '',
    loadChildren: () => import('./@layout/blank-layout/blank-layout.module').then(m => m.BlankLayoutModule),
    // canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: "always"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
