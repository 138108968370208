import {PlansModel} from '../../models/subscription/plans.model';
import {environment} from '../../../../environments/environment';

export const SubscriptionPlans: Array<PlansModel> = [
  {
    slug: 'lite',
    name: 'LITE',
    id: 1,
    cost: {
      monthly: {
        usd: 15,
        inr: 1200
      },
      annually: {
        usd: 9,
        inr: 720,
      },
      total_annual: {
        usd: 108,
        inr: 8640,
      }
    },
    plan_pricing: {
      monthly: environment.planPricing.lite.monthly,
      annually: environment.planPricing.lite.yearly
    },
    qrCode_limit: 5,
    badge_text: $localize`Lite Features`,
    pop_content: $localize` A Lite account allows you to create upto 5 QR Codes in total, irrespective of subscription
     period. To create more than 5 QR Codes, upgrade plan or delete existing QR Codes`,
    display_feature: 'showLiteFeatures',
    features: [
      {
        id: 1,
        text: $localize`Unlimited Scans`,
        support_text: $localize`There is no limit on the number of scans you can get from your QR Codes`
      },
      {
        id: 2,
        text: $localize`No Ads`,
        support_text: $localize`No promotional ads on QR Code images, landing pages, or pages specified by you.
        Note that some free QR Code scanning apps add promotional ads, which cannot be controlled by Scanova.`
      },
      {
        id: 3,
        text: $localize`Dynamic/Editable QR Codes`,
        support_text: $localize`With Dynamic/Editable QR Codes, you can edit linked content anytime without the need
        to recreate/reprint the QR Code. Dynamic QR Codes also allow scan tracking.`
      },
      {
        id: 4,
        text: $localize`Static/Direct QR Codes`,
        support_text: $localize`Static QR Codes are permanent QR Codes i.e. content is not linked by directly encoded
        into the QR Code. Static QR Codes cannot be edited and do not allow scan tracking.`
      },
      {
        id: 5,
        text: $localize`Custom-designed QR Codes`,
        support_text: $localize`Customize the design of the QR Code by adding colors, patterns, logos, frames and
        background images. Custom-designed QR Codes typically attract more scans. `
      },
      {
        id: 6,
        text: $localize`Mobile Landing Pages`,
        support_text: $localize`Create mobile-optimized landing pages simply by adding content. No developer or domain
         required. Available landing pages include - Coupon, VCard Profile, Social Media, Product,Event, Wedding, and
         Custom Page. In Lite and Standard plan, these landing pages will have a \'Powered by Scanova\' footer.`
      },
      {
        id: 7,
        text: $localize`Export - PNG, JPG, SVG, PDF, EPS`,
        support_text: $localize`Export QR Code images in raster (PNG, JPG) or vector (SVG, EPS, PS, PDF) formats`
      },
      {
        id: 8,
        text: $localize`Basic Analytics`,
        support_text: $localize`With basic analytics, you only get the total scans by time period for selected QR
         Code/s or tags`
      },
      {
        id: 9,
        text: $localize`Multi-factor Authentication (MFA)`,
        support_text: $localize`With MFA, you can setup an additional layer of security either via email OTP or
        Authenticator app OTP`
      },
      {
        id: 10,
        text: $localize`Landing Page Components - Regular`,
        support_text: $localize`Using this feature, you can add the following components on landing pages (wherever
        applicable): Button, Banner Image, Description Box, Event Schedule, Event Venue, File, Map Location, RSVP,
        Social Sharing Button, and Video URL`
      },
      {
        id: 11,
        text: $localize`Tags`,
        support_text: $localize`Assign multiple tags (or labels) to QR Codes to group them and view tag-level analytics`
      },
      {
        id: 12,
        text: $localize`Chat Support`,
        support_text: $localize`Get live chat support from our customer support executive on working days`
      },
      {
        id: 13,
        text: $localize`Email Support`,
        support_text: $localize`Get email support from our support team within 24 hours of your query on working days`
      }

    ],
    advanced_features: []
  },
  {
    slug: 'standard',
    name: 'STANDARD',
    id: 2,
    cost: {
      monthly: {
        usd: 50,
        inr: 4000
      },
      annually: {
        usd: 35,
        inr: 2800,
      },
      total_annual: {
        usd: 420,
        inr: 33600,
      }
    },
    qrCode_limit: 25,
    badge_text: $localize`Standard Features`,
    pop_content: $localize`A Standard account allows you to create upto 25 QR Codes in total, irrespective of subscription
     period. To create more than 25 QR Codes, upgrade plan or delete existing QR Codes`,
    display_feature: 'showStandardFeatures',
    plan_pricing: {
      monthly: environment.planPricing.standard.monthly,
      annually: environment.planPricing.standard.yearly
    },
    features: [
      {
        id: 1,
        text: $localize`All Lite Features`,
        support_text: $localize`In Standard Plan, you get access to all Lite features and additional features.`
      },
      {
        id: 2,
        text: $localize`Custom Dynamic URL Hash`,
        support_text: $localize`When Dynamic QR Codes are scanned, users see Scanova's Dynamic URL of the format
        https://scnv.io/ABCD. With this feature, you can customize the hash ('ABCD') to match your brand or campaign
        (e.g., https://scnv.io/mycampaign)`
      },
      {
        id: 3,
        text: $localize`Password Protected Codes`,
        support_text: $localize`Add password-protection to Dynamic QR Codes. Users will have to enter a password
        specified by you to access content of the QR Code.`
      },
      {
        id: 4,
        text: $localize`Save QR Code Design Templates`,
        support_text: $localize`Save QR Code designs you create as templates for quicker designing of subsequent QR Codes`
      },
      {
        id: 5,
        text: $localize`Full Analytics`,
        support_text: $localize`With full analytics, get number of scans by time period, location (city/country),
        device (mobile, tablet, and PC), handset, browser, and operating system via visually-appealing infographics`
      },
      {
        id: 6,
        text: $localize`Export Analytics`,
        support_text: $localize`Export infographic data and raw data in CSV/Excel formats`
      },
      {
        id: 7,
        text: $localize`Google Analytics Integration`,
        support_text: $localize`Integrate QR Code analytics with your Google Analytics account`
      },
      {
        id: 8,
        text: $localize`Lead Generation`,
        support_text: $localize`Using lead generation, capture contact information of users when QR Codes are scanned.
         This lead capture landing page will have a \'Powered by Scanova\' footer.`
      },
      {
        id: 9,
        text: $localize`Landing Page Component - Custom Form`,
        support_text: $localize`Using this feature, you can add all Lite plan components on landing pages (wherever
        applicable) and additional Custom Form component`
      }

    ],
    advanced_features: []
  },
  {
    slug: 'pro',
    name: 'PRO',
    id: 3,
    cost: {
      monthly: {
        usd: 100,
        inr: 8000
      },
      annually: {
        usd: 75,
        inr: 6000,
      },
      total_annual: {
        usd: 900,
        inr: 72000,
      }
    },
    qrCode_limit: 250,
    badge_text: $localize`Pro Features`,
    pop_content: $localize`A Pro account allows you to create upto 250 QR Codes in total, irrespective of subscription
    period. To create more than 250 QR Codes, upgrade plan to Enterprise or delete existing QR Codes`,
    display_feature: 'showProFeatures',
    plan_pricing: {
      monthly: environment.planPricing.pro.monthly,
      annually: environment.planPricing.pro.yearly
    },
    features: [
      {
        id: 1,
        text: $localize`All Standard Features`,
        support_text: $localize`In Pro Plan, you get access to all Lite and Standard features, and additional features.`
      },
      {
        id: 2,
        text: $localize`1 Custom Domain`,
        support_text: $localize`When Dynamic QR Codes are scanned, users see Scanova's Dynamic URL of the format
        https://scnv.io/ABCD. With this feature, you can replace the Dynamic URL with your own sub-domain—1 sub-domain—
         (e.g., https://campaign1.mywebsite.com/ABCD) to maintain branding. Note that this feature will not affect
         editability or scan tracking but requires assistance of a developer.`
      },
      {
        id: 3,
        text: $localize`Auto-deactivate QR Codes`,
        support_text: $localize`You can enable this feature with Dynamic QR Codes to automatically expire/deactivate a
        QR Code on a specified date and time.`
      },
      {
        id: 4,
        text: $localize`White-labeled Mobile Landing Pages`,
        support_text: $localize`Create mobile-optimized landing pages simply by adding content. No developer or domain
        required. Available landing pages include - Coupon, VCard Profile, Social Media, Product, Event, Wedding, and
        Custom Page. In Pro plan, these landing pages will not have a \'Powered by Scanova\' footer.`
      },
      {
        id: 5,
        text: $localize`White-labeled Lead Generation`,
        support_text: $localize`Using lead generation, capture contact information of users when QR Codes are scanned.
         This lead capture landing page will not have a \'Powered by Scanova\' footer.`
      },
      {
        id: 6,
        text: $localize`Exact GPS Location of Scans`,
        support_text: $localize`You can enable this feature with Dynamic QR Codes to get exact GPS location of scanning
         device (with user\'s permission only). End-users will see a pop-up prompting them to allow/deny location before
          viewing the content.`
      },
      {
        id: 7,
        text: $localize`Event/Click Tracking`,
        support_text: $localize`Using this feature, you can track and get data on the number of clicks you\'re getting
        on buttons added to mobile landing pages created using Scanova QR Code Generator`
      },
      {
        id: 8,
        text: $localize`Analytics Webhooks Integration`,
        support_text: $localize`Post tracking data (in JSON format) to a specific URL (e.g. Zapier) every time a Dynamic
         QR Code is scanned`
      },
      {
        id: 9,
        text: $localize`Add upto 5 users`,
        support_text: $localize`Add up to 5 users to help you manage your account via three permission levels—Viewer,
         Manager, and Admin`
      },
      {
        id: 10,
        text: $localize`Bulk Operations (Batch size 100)`,
        support_text: $localize`With this feature, you can generate, update, and download Dynamic QR Codes in bulk with
        a batch size up to 100`
      },
      {
        id: 11,
        text: $localize`Condition Redirection`,
        support_text: $localize`Using this feature, you can select the redirection condition (country, device, or
        operating system) and specify the URL to which the end-user(s) would be redirected upon scanning`
      },
      {
        id: 12,
        text: $localize`Phone Support`,
        support_text: $localize`In addition to email, get phone support from our support team within 24 hours of your
         query on working days`
      },
    ],
    advanced_features: []
  },
  {
    slug: 'enterprise',
    name: 'ENTERPRISE',
    id: 4,
    cost: {
      monthly: {
        usd: 0,
        inr: 0
      },
      annually: {
        usd: 0,
        inr: 0,
      },
      total_annual: {
        usd: 0,
        inr: 0,
      }
    },
    enterprise_text: $localize`Tiered QR Codes`,
    plan_pricing: {
      monthly: '0',
      annually: '0'
    },
    badge_text: $localize`Enterprise Features`,
    pop_content: $localize`Depending on your requirements, you can choose the number of QR Codes you want to added to
     your account, irrespective of the subscription period.`,
    display_feature: 'showEnterpriseFeatures',
    features: [
      {
        id: 1,
        text: $localize`All Pro Features`,
        support_text: $localize`With the Enterprise Plan, you get access to all Pro features, and additional features
      can be customized to your plan as per requirements.`
      },
      {
        id: 2,
        text: $localize`Tiered Custom Domains`,
        support_text: $localize`When Dynamic QR Codes are scanned, users see Scanova's Dynamic URL of the format
         https://scnv.io/ABCD. With this feature, you can replace the Dynamic URL with your own sub-domain (e.g.,
         https://campaign1.mywebsite.com/ABCD) to maintain branding. Depending on your requirement, you can choose the
         number of Custom Domains you want to add to your account.`
      },
      {
        id: 3,
        text: $localize`Tiered Users`,
        support_text: $localize`Depending on your requirement, you can choose the number of Users you can add to help
        you manage your account via default (Viewer, Manager, Administrator) and custom roles`
      },
      {
        id: 4,
        text: $localize`Custom User Permissions`,
        support_text: $localize`With this feature, you can create custom user roles with different permissions which
        can be assigned to invited users.`
      },
      {
        id: 5,
        text: $localize`MFA Enforcement`,
        support_text: $localize`With this optional feature, you can enforce and monitor MFA status for all users
        who are invited to access your account.`
      },
      {
        id: 6,
        text: $localize`Single Sign-on (SSO)`,
        support_text: $localize`With this feature, use your organization’s Single Sign-on (SSO) authentication method to securely login into your company’s Scanova account`
      },
      {
        id: 7,
        text: $localize`User Access by Tags`,
        support_text: $localize`With this feature, you can restrict invited users to access QR Codes that have specific tags. This will allow you to ensure only authorised invitees have access to specific QR Codes.`
      },
      {
        id: 8,
        text: $localize`Data Security Compliance`,
        support_text: $localize`Your Enterprise plan can be customized to ensure data collection and reporting is as per your company and data security policies.`
      },
      {
        id: 9,
        text: $localize`Insurance Compliance`,
        support_text: $localize`If required, an insurance endorsement certificate can be issued in your company name to help you comply with supplier onboarding policies.`
      },
      {
        id: 10,
        text: $localize`Bulk Operations (Batch size 500)`,
        support_text: $localize`With this feature, you can generate, update, and download Dynamic QR Codes in bulk with
        a batch size up to 500`
      },
      {
        id: 11,
        text: $localize`Dedicated Relationship Manager`,
        support_text: $localize`In addition to phone support, a dedicated relationship manager will be assigned to your company, who will act as the single point-of-contact.`
      },
      {
        id: 12,
        text: $localize`Landing Page Components - Enterprise`,
        support_text: $localize`Using this feature, you can add all the Pro plan components on landing pages (wherever
        applicable) and also additional components like Video Upload and Custom Script`
      }
    ],
    advanced_features: []
  },
  {
    name: $localize`FREE`,
    id: 5,
    cost: {
      monthly: {
        usd: 0,
        inr: 0
      },
      annually: {
        usd: 150,
        inr: 11105,
      },
      total_annual: {
        usd: 0,
        inr: 0,
      }
    },
    plan_pricing: {
      monthly: environment.planPricing.free.monthly,
      annually: environment.planPricing.free.yearly
    },
    features: [
      {
        id: 1,
        text: $localize`Need a single dynamic QR Code permanently?`,
        support_text: $localize`Pay a one-time fee and create a single Dynamic QR Code with
            lifetime validity. This plan includes only 1 Dynamic QR Code of any category and all LITE plan features.\n`
      },
    ],
    advanced_features: []
  },
  // {
  //   name: `1 Day Plan`,
  //   id: environment.environment === 'qa' ? 11 : 13,
  //   cost: {
  //     monthly: {
  //       usd: 15,
  //       inr: null
  //     },
  //     annually: {
  //       usd: 9,
  //       inr: null,
  //     },
  //     total_annual: {
  //       usd: 108,
  //       inr: null,
  //     }
  //   },
  //   qrCode_limit: 5,
  //   plan_pricing: {
  //     monthly: environment.planPricing.oneDayPlan.monthly,
  //     annually: environment.planPricing.oneDayPlan.yearly
  //   },
  //   features: [
  //     {
  //       id: 1,
  //       text: $localize`Just a Test Plan`,
  //       support_text: $localize`Test Plan Features\n`
  //     },
  //   ],
  //   advanced_features: []
  // }
];
