import {APP_INITIALIZER, NgModule} from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthInterceptor} from "./@shared/interceptors/auth.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {FormlyModule} from '@ngx-formly/core';
import {
  ArrayTypeComponent,
  InputTypeComponent,
  NullTypeComponent,
  ObjectTypeComponent,
  TagTypeComponent
} from "./@core/schema/types";

import {ErrorsModule} from "./@errors/errors.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {provideCharts, withDefaultRegisterables} from "ng2-charts";
import {provideNgxStripe} from "ngx-stripe";
import {environment} from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ErrorsModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      countDuplicates: true,
      enableHtml: true,
      preventDuplicates: true
    }),
    FormlyModule.forRoot({
      extras: { lazyRender: true }, types: [
        { name: 'input', component: InputTypeComponent },
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number',
            },
          },
        },
        { name: 'boolean', extends: 'checkbox' },
        { name: 'enum', extends: 'select' },
        { name: 'null', component: NullTypeComponent, wrappers: ['form-field'] },
        { name: 'object', component: ObjectTypeComponent },
        { name: 'array', component: ArrayTypeComponent },
        { name: 'tag', component: TagTypeComponent }
      ]
    }),
    // FormlyBootstrapModule,
    // FormlyModule.forRoot(),
    NgSelectModule,
    NgxDatatableModule
  ],
  providers: [
    provideCharts(withDefaultRegisterables()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxStripe(environment.stripePublishableKey)
  ]
})
export class AppModule { }
