import {Component} from '@angular/core';
import {FieldType, FieldTypeConfig} from '@ngx-formly/core';

@Component({
  selector: 'formly-array-type',
  template: `
    <div class="form-group">
      <label class="form-control-label" [htmlFor]="field.id" *ngIf="props['label']">{{ props['label'] }}</label>
      <p *ngIf="props['description']">{{ props['description'] }}</p>

      <!--    <div *ngFor="let field of field.fieldGroup;let i = index;" class="row">-->
      <!--      <formly-field class="col-10" [field]="field"></formly-field>-->

      <ng-select
        [addTag]="true"
        [multiple]="true"
        [hideSelected]="true"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [placeholder]="props['placeholder'] || ''">
      </ng-select>
      <form-errors [control]="formControl"></form-errors>

      <!--      <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">-->
      <!--        {{formControl.errors|json}}-->
      <!--        <formly-validation-message [field]="field"></formly-validation-message>-->
      <!--      </div>-->
      <!--    </div>-->
    </div>
  `,
})
export class TagTypeComponent extends FieldType<FieldTypeConfig> {
}
