import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ErrorsService} from './errors-service/errors.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {ServerErrorsInterceptor} from './error-interceptors/server-errors.interceptor';
import {ErrorsHandler} from "./errors-handler/errors.handler";

const errorRoutes: Routes = [
  // {path: '', component: ErrorsComponent},
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(errorRoutes)
  ],
  exports: [
  ],
  providers: [
    ErrorsService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
      // useValue: Sentry.createErrorHandler({
      //   showDialog: false,
      // }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true
    }
  ]
})
export class ErrorsModule {
}
